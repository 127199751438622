import { Injectable } from "@angular/core"; 
import { HttpClient, HttpHeaders } from "@angular/common/http"; 

import {
    LIEN_GET_INFOS_SHOP,
    //LIEN_GET_ALL_HORAIRES,
    LIEN_GET_HORAIRES_SHOP,
    LIEN_GET_SHOP_ID,
  
    LIEN_ADD_NEW_SHOP
} from "../providers/config_bdd";

@Injectable()
export class ShopsProvider{
    constructor( public http : HttpClient){

    }

    //récupérer les informations du shop 
    getAllInfosShop(): any{
        return this.http.post(LIEN_GET_INFOS_SHOP
            ,{
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            })
    }

    /*getAllHoraires(idshop): any {
        var myData = JSON.stringify({idshop : idshop});
        return this.http.post(LIEN_GET_ALL_HORAIRES, myData
            ,{
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            })
    }*/

  

   
    /*** */

            //Horaires Shop
getHorairesShop(idshop): any {
    var myData = JSON.stringify({ idshop: idshop });
    return this.http.post(LIEN_GET_HORAIRES_SHOP, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
  //get a shop  id
  getShopID(idshop): any {
    var myData = JSON.stringify({ idshop: idshop });
    return this.http.post(LIEN_GET_SHOP_ID, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
/******
 * Ajout d'un nouveau client, suite aux saisies de l'utilisateur
 ****************************************************************/

 addNewShop(nomshop, adresseshop,codepostalshop, villeshop,paysshop,emailshop,telephoneshop, isactiveshop,horaires
    
    ):any{
   /* let myData = JSON.stringify({
        nomshop: nomshop,
        adresseshop: adresseshop,
        codepostalshop: codepostalshop,
        villeshop: villeshop,
        paysshop: paysshop,
        emailshop : emailshop, 
        telephoneshop : telephoneshop, 
        isactiveshop : isactiveshop,
        horaires:   horaires

        
    });*/
    let myData = JSON.stringify({
        nomshop,
        adresseshop,
        codepostalshop,
        villeshop,
        paysshop,
        emailshop , 
        telephoneshop , 
        isactiveshop ,
        horaires   
    });
    console.log("MyData  Add new SHOP : " + myData);
    return this.http.post(LIEN_ADD_NEW_SHOP, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}
    
}
