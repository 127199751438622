import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';

const baseURL2 = 'https://apps.rexiagroup.com/api';
const paymentURL = baseURL2 + "/subscription";

// const baseURL2 = 'https://apps.helvitech.com/api';

// const subscriptionURL = baseURL2 + "/payments";


@Injectable({
  providedIn: 'root'
})


export class MiscService {

 

  private headers: HttpHeaders = new HttpHeaders()
  .set('Content-Type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
 
  constructor(private http: HttpClient) { }

  public static roundTwoDecimals(val: Number):any {

   // return Math.round((Number(val) + Number.EPSILON) * 100) / 100;
   return Number(val).toFixed(2);
   }

   payOrder(data): Observable<any> {
    //return this.http.post(`${subscriptionURL}${RENEW_SUBSCRIPTION}`, data, { headers: this.headers });
    return this.http.post( `${paymentURL}/pay`, data, { headers: this.headers } );
  };

   
}
