import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CartecreditformPageRoutingModule } from './cartecreditform-routing.module';

import { CartecreditformPage } from './cartecreditform.page';

// import {CardModule} from 'ngx-card/ngx-card';
// import { CardModule } from 'ngx-card';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        CartecreditformPageRoutingModule,
        // CardModule
    ],
    declarations: [CartecreditformPage]
})
export class CartecreditformPageModule {}
