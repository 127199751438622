/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout traitement des produits.
*  Auteur : Helvitech Dev Team 
*  Date : 01.06.2020
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour les produits.
******************************************************************************/
import {
    LIEN_GET_ALL_PRODUITS,
    LIEN_GET_ALL_PRODUITS_BUSINESS,

    LIEN_ADD_NEW_PRODUIT,   //ajout d'un nouveau produit

    LIEN_GET_ALL_CATEGORIE_PRODUIT, //récupérer toutes les categories de produits
    LIEN_GET_ALL_TYPE_PRODUITS,
    LIEN_ADD_NEW_TYPEPRODUIT,

    LIEN_GET_ALL_CATEGORIE_PRODUITS,
    LIEN_GET_ALL_SOUS_CATEGORIE_PRODUITS,

    //récupérer la liste des types de produits, selon le nom du type de shop du business connecté
    LIEN_GET_TYPE_PRODUITS_BY_BUSINESS_ID_TYPE_SHOP,
    //Récupérer les informations du type de shop du business connecté : 
    LIEN_GET_TYPE_SHOP_BUSINESS_CONNECTED,

    //Récupérer les catégorie du type de produit du type de shop du business connecté 
    LIEN_GET_CATEGORIE_TYPE_SHOP_BUSINESS_CONNECTED,

    //récupérer les types de produit selon la catégorie
    LIEN_GET_TYPEPRODUIT_BY_CATEGORIE,

    //***  Récupérer les produits selon leur type  ***//
    LIEN_GET_TYPEPRODUIT_PIZZA_PRODUITS,
    //récupérer le prix d'un produit selon sa taille et type de livraison
    LIEN_GET_PRIX_PRODUIT_TAILLE_LIVRAISON
    /*LIEN_GET_TYPEPRODUIT_POULET_PRODUITS,
    LIEN_GET_TYPEPRODUIT_SALADES_PRODUITS,
    LIEN_GET_TYPEPRODUIT_CAFE_PRODUITS,
    LIEN_GET_TYPEPRODUIT_VIENNOISERIE_PRODUITS,
    LIEN_GET_TYPEPRODUIT_SPIRITUEUX_PRODUITS,
    LIEN_GET_TYPEPRODUIT_ROUGE_PRODUITS,
    LIEN_GET_TYPEPRODUIT_ROSES_PRODUITS,
    LIEN_GET_TYPEPRODUIT_BIERES_PRODUITS,
    LIEN_GET_TYPEPRODUIT_BOISSONS_PRODUITS,
    LIEN_GET_TYPEPRODUIT_SAUCESEXTRAS_PRODUITS,
    LIEN_GET_TYPEPRODUIT_CAMBO_PRODUITS,
    LIEN_GET_TYPEPRODUIT_OTHERS_PRODUITS,
    LIEN_GET_TYPEPRODUIT_GARLICBREAD_PRODUITS,
*/
    
} from "../providers/config_bdd";


@Injectable()
export class ProduitProvider {
    constructor(public http: HttpClient) {

    }

    //Récupérer tous les shops/business présent dans la base de données
    getAllProduits(): any {
        return this.http.post(LIEN_GET_ALL_PRODUITS
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            }
        );
    }

    //Récupérer les produits d'un business connecté; selon son identifiant
    getAllProduitsBusiness(idshop): any {
        var myData = JSON.stringify({ idshop: idshop });
        return this.http.post(LIEN_GET_ALL_PRODUITS_BUSINESS, myData
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            });
    }

    //Ajout d'un produit par un business connecté 
    addNewProduit(nomproduit, prixproduit, idtypeproduit, idcategorie): any {
        let myData = JSON.stringify({
            nomproduit: nomproduit,
            prixproduit: prixproduit,
            idtypeproduit: idtypeproduit,
            idcategorie: idcategorie
        });
        console.log("MyData Produit Provider Add new produit : " + myData);
        return this.http.post(LIEN_ADD_NEW_PRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Ajouter un nouveau type de produit : 
    addNewTypeProduit(nomtypeproduit, idcategorie){
        let myData = JSON.stringify({
            nomtypeproduit : nomtypeproduit,
            idcategorie : idcategorie
        });
        return this.http.post(LIEN_ADD_NEW_TYPEPRODUIT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }


    /***********************************************************
     * Pour récupérer tous les types et catégories de produits *
     ***********************************************************/
    //liste des types de produits : 
     getAllTypeProduits(): any {
        return this.http.post(LIEN_GET_ALL_TYPE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //liste des catégories de produits : 
    getAllCategorieProduits() : any{
        return this.http.post(LIEN_GET_ALL_CATEGORIE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //liste des sous-catégories de produits : 
    getAllSousCategorieProduits(): any{
        return this.http.post( LIEN_GET_ALL_SOUS_CATEGORIE_PRODUITS, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //-------------------------------------------------------------------------------**
    //Récupérer la liste des type de produits, selon le type de business connecté
    getTypeProduitByBusinessIdTypeShop(idtypeshop):any{
        let myData = JSON.stringify({
            idtypeshop : idtypeshop
        });
        return this.http.post(LIEN_GET_TYPE_PRODUITS_BY_BUSINESS_ID_TYPE_SHOP, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Récupérer les informations du typeShop du business connecté : 
    getTypeShopBusinessConnected(nomtypeshop):any{
        let myData = JSON.stringify({
            nomtypeshop : nomtypeshop
        });
        return this.http.post(LIEN_GET_TYPE_SHOP_BUSINESS_CONNECTED, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    //Récupérer les catégorie de produit du type de produit du business connecté : 
    getTypeProduitByCategorie(idcategorie): any{
        let myData = JSON.stringify({
            idcategorie : idcategorie
        });
        return this.http.post(LIEN_GET_TYPEPRODUIT_BY_CATEGORIE, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

    /*****************************************
     * Récupérer les produits selon son type *
     *****************************************/
    //Pizza : 
    getTypePizzaProduits(idtypeproduit):any{
        let myData = JSON.stringify({
            idtypeproduit : idtypeproduit
        })
        return this.http.post(LIEN_GET_TYPEPRODUIT_PIZZA_PRODUITS, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
    //récupérer lée prix d'un produit selon le type de livraison
    getPrixTaillePizza(idproduit,taille,idtypelivraison):any{
        let myData = JSON.stringify({
            idproduit : idproduit,
            taille : taille,
            idtypelivraison : idtypelivraison
        })      
        return this.http.post(LIEN_GET_PRIX_PRODUIT_TAILLE_LIVRAISON, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }

}