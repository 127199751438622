import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { DetailproduitPageModule } from './produits/detailproduit/detailproduit.module';
import { ProduitProvider } from 'src/providers/produits_provider';
import { ComposantPizzaProvider } from 'src/providers/composantpizza_provider';
import { ClientProvider } from 'src/providers/clients_provider';
import { CommandesClientProvider } from 'src/providers/commandesclient_provider';

import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CartecreditformPageModule } from './cartecreditform/cartecreditform.module';
import { DetailproduitPage } from './produits/detailproduit/detailproduit.page';

import { Stripe } from '@ionic-native/stripe/ngx';
import { DetailcommandePageModule } from './detailcommande/detailcommande.module';
import { MescommandesPageModule } from './mescommandes/mescommandes.module';
import { MiscService } from './services/misc.service';

import { IonicSelectableModule } from 'ionic-selectable';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ShopsProvider } from 'src/providers/shops_provider';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule, IonicModule.forRoot(), AppRoutingModule,
        IonicStorageModule.forRoot(),
        HttpClientModule,
        //Produits : 
        DetailproduitPageModule,
        MescommandesPageModule,
        DetailcommandePageModule,
        CartecreditformPageModule,
        FormsModule, ReactiveFormsModule,
        IonicSelectableModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        //{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        ProduitProvider,
        ComposantPizzaProvider,
        ClientProvider,
        CommandesClientProvider,
        NativeStorage,
        Stripe,
        ShopsProvider,
        FormsModule, ReactiveFormsModule,
        MiscService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
