import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

// const baseUrl = 'https://localhost:13131/api/payments';
// const baseUrlCards = 'https://localhost:13131/api/cards';



//clé pour faire les tests
export const StripeTestPK = 'pk_test_51H56BFAXWVcdYU4ymVxHQ7PxjMGDyVdtwIDCs6arTPQh5JZ7kMabw17q43q4mSJ7uQ7zyVIdCOlh9ujFH5sswLn70096fIhKoU';
 /*const baseUrl = 'http://apps.rexiagroup.com:13131/api/payments';
 const baseUrlCards = 'http://apps.rexiagroup.com:13131/api/cards';

 const baseUrl = 'http://apps.helvitech.com:13131/api/payments';
const baseUrlCards = 'http://apps.helvitech.com:13131/api/cards';*/


//clé production
export const StripeLivePK = 'pk_live_51HHSuBL9YtJhRw2iaQEaYblR82R5wOzg0vSSJfncOynK2IKqmatDzB4oXu9JfT0otpolPRfQO3uHgcVnB6OKe4iC00OULBaIdq';
//const baseUrl = 'http://apps.helvitech.com:13131/api/payments';
//const baseUrlCards = 'http://apps.helvitech.com:13131/api/cards';


const baseUrl = 'https://apps.helvitech.com/api/payments';
const baseUrlCards = 'https://apps.helvitech.com/api/cards';


@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  options = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*'
    })
  };

  constructor( private http: HttpClient ) { }

  // Create payment
  create(data): Observable<any> {
    // console.log ("Create payment with: ", data);
    return this.http.post(baseUrl, data, this.options);
  }
  // Find cards for using clientID
  // findPaymentsByClientNames(clientNames): Observable<any> {
  //   return this.http.get(`${baseUrl}?clientNames=${clientNames}`);
  // }

  // Find cards for using clientID
  findPaymentsByClientNames(data): Observable<any> {
    return this.http.post(`${baseUrl}/findAll`, data, this.options);
  }
  // Find cards for using clientID
  findPaymentsByIdClient(data): Observable<any> {
    return this.http.post(`${baseUrl}/idClient`, data, this.options);
  }

  // Find cards for using clientID
  findPaymentsByEmailClient(data): Observable<any> {
    return this.http.post(`${baseUrlCards}/emailClient`, data, this.options);
  }

  // Save Card
  createCard(data): Observable<any> {
    return this.http.post(baseUrlCards, data, this.options);
  }

  // Find cards for using clientID
  findCardsByClientNames(data): Observable<any> {
    return this.http.post(`${baseUrlCards}/findAll`, data, this.options);
  }

  // Find cards for using clientID
  findCardsByIdClient(data): Observable<any> {
    return this.http.post(`${baseUrlCards}/idClient`, data, this.options);
  }

  // Find cards for using clientID
  findCardsByEmailClient(data): Observable<any> {
    return this.http.post(`${baseUrlCards}/emailClient`, data, this.options);
  }
}
