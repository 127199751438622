/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, OnInit, Input } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { panier } from '../app.component';
import { CommandeClient } from 'src/models/commandesclient_model';
import { element } from 'protractor';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { ComposantPizzaProvider } from 'src/providers/composantpizza_provider';
import { isNumber, isString } from 'util';
import { ProduitProvider } from 'src/providers/produits_provider';
import { GlobalVariableService } from '../services/globalVariables.service';

@Component({
  selector: 'app-detailcommande',
  templateUrl: './detailcommande.page.html',
  styleUrls: ['./detailcommande.page.scss'],
})
export class DetailcommandePage implements OnInit {
  listeProduits: any;
  panier: CommandeClient;
  total: any;

  commande : CommandeClient;
  commandeStockee: CommandeClient;
  PrixGarnituresSupplementaire: any[] = [];
  allComposants: any[] = [];
  allProduits: any[] = [];
  allOffres: any[] = [];

  public restaurantName : string;


  constructor(
    //public navParams: NavParams, 
    private router: Router,
    private route : ActivatedRoute,
    private composantProvider: ComposantPizzaProvider,
    private produitProvider: ProduitProvider,
    private globalVariables: GlobalVariableService
    ) {
      
    //console.log("this.panier in detailcommande :"+this.panier);
    // this.listeProduits = this.panier.listeProduits;

    /*------------------------------------------------------------------------------------------
    Jorge : mise en commentaire des 4 ligne suivante pour passer d'un modal à une page normale 
    et passer des informations.
    -------------------------------------------------------------------------------------------*/
   // console.log("hello detail :" + this.navParams.get('panier'));
    //this.panier = this.navParams.get('panier');
    //this.listeProduits = this.panier.listeProduits;
    //this.total = this.panier.totalcom;


    this.restaurantName = this.globalVariables.restaurantName;

    this.route.queryParams.subscribe(params => {
      if(this.router.getCurrentNavigation().extras.state){
        this.commande = this.router.getCurrentNavigation().extras.state.commande;      
        this.commandeStockee=  this.commande;
        localStorage.setItem('commandeStockee',JSON.stringify(this.commandeStockee)) ; 
       
        console.log("******localStorage.getItem('commandeStockee') Constructor en question : " ,JSON.parse(JSON.stringify(localStorage.getItem('commandeStockee'))));
      }
      else{ this.commande =<CommandeClient>(JSON.parse(((localStorage.getItem('commandeStockee')))));
        console.log("localStorage.getItem('commandeStockee') Constructor en question : " ,JSON.parse((JSON.stringify(localStorage.getItem('commandeStockee')))));}
     // if(this.commande == null){alert("this.commande est null il faut revenir à mes commandes!!")}
    //  this.showCommande(this.commande);
    console.log(" this.commandeStockee Constructor en question : " , this.commande);
    });
    this.getAllComposantsPizza();
    this.getPrixGarnituresSupplementaire();
    this.getProduitsCombos(26);
    this.getProduitsOffres(27);
  }
 
  showCommande(commande : CommandeClient){
    console.log("La commande en question : " , commande[0]);
  }
  ngOnInit() {
    if(this.commande == null){
      this.commande =<CommandeClient>(JSON.parse(((localStorage.getItem('commandeStockee')))));
      console.log(" hello "+JSON.stringify(localStorage.getItem('commandeStockee')));
   //   this.commande= Array(localStorage.getItem('commandeStockee'));
     // this.router.navigate(['mescommandes']);
    }
      else{
        this.commande =<CommandeClient>(JSON.parse(((localStorage.getItem('commandeStockee')))));
        this.commandeStockee=  this.commande;
        localStorage.setItem('commandeStockee',JSON.stringify(this.commande)) ;        
      }
      console.log("La commande Constructor en question : " , this.commande);
  }

  AddPanier() {

    panier.demandeur =0;
    panier.heurelivraison = "";
    panier.idclient =  this.commande.idclient;
    panier.idshop =  this.commande.idshop;
    panier.idtypelivraison =  this.commande.idtypelivraison;
   // panier.totalcom =  this.commande.totalcom;
    // panier.listeProduits= this.panier.listeProduits;

    this.commande.listeProduits.forEach(element => {
      /** */
      //si c'est une pizza
      if ( element.idtypeproduit == 1) {
        //chercher prix  actuelle
        element.listeprix.forEach(prix=>{
          if(prix['taille'] == element.taille){
            element.prixproduit = (prix['prix_livraison']);
          }
        });
        //change prix de la garniture des pizza
        this.changePrixTaille(0,element);
        //changer le prix des suppléments choisis
       
        element.listeSupplements.forEach(supplement=>{
          this.allComposants.forEach(element => {
            //alert("Début prix :"+  element.listeIngredients);
            if (element.categoriecomposant == "garnitures") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){ 
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
            if (element.categoriecomposant == "pâte") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
            if (element.categoriecomposant == "sauces") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
          });
  
        // supplement.nomcomposant
        });

        

      }
      //si c'est une salade
      if (element.idtypeproduit == 2) {
        this.allComposants.forEach(element => {
          //alert("Début prix :"+  element.listeIngredients);
          if (element.categoriecomposant == "salad") {
            let prix: any;
            this.PrixGarnituresSupplementaire.forEach(prixGaR => {
              if (prixGaR.taille == "salad") {
                prix = prixGaR.prix;
              }
            });
            element.listeIngredients.forEach(cp => {
              cp.prixcomposant = prix;
            });
          }
        });
      }
      if ( element.idtypeproduit == 27) {
        let sommeProduitsOffre=0.00;
        //chercher prix  actuelle
        element.listeOffre.forEach(offre=>{
          offre.listeprix.forEach(prix=>{
            if(prix['taille'] == offre.taille){
              offre.prixproduitOffre = (prix['prix_livraison']);
            }
          });        
        });
        //charger le nouveau prix de l'offre
          //trouver la moins chère       
            let index =0;
            for (let i = 0; i <  element.listeOffre.length; i++) {
             // alert("prix pizza :"+Number( element.listeOffre[i].prixproduitOffre));
            if(Number(element.listeOffre[i].prixproduitOffre)<=Number(element.listeOffre[index].prixproduitOffre))
             {
              index=i;
             }
            }
               
//calcule de la somme des pizza de l'offre
//récupérer la remise
let remise:number;
this.allOffres.forEach(offre=>{
 
  if(offre.idproduit == element.idproduit){
    offre.ComposantsOffres.forEach( rm=> {
      remise = rm.remise;
      // alert("la remise est de :"+remise);
    });
   
  }
});
for (let i = 0; i <  element.listeOffre.length; i++) {
  if(i!=index){
  sommeProduitsOffre+=Number( element.listeOffre[i].prixproduitOffre);}
  if(i==index){
    // alert("Avant element le moins cher est "+element.listeOffre[i].prixproduitOffre);
    element.listeOffre[i].prixproduitOffre=(Number( element.listeOffre[i].prixproduitOffre) * Number(remise))/100;
    //  alert("Après element le moins cher est "+element.listeOffre[i].prixproduitOffre);
    sommeProduitsOffre+=Number( element.listeOffre[i].prixproduitOffre);}
  
}
element.prixproduit = sommeProduitsOffre;
        //change prix de la garniture des pizza
       // this.changePrixTaille(0,element);
        //changer le prix des suppléments choisis
       
      /*  element.listeSupplements.forEach(supplement=>{
          this.allComposants.forEach(element => {
            //alert("Début prix :"+  element.listeIngredients);
            if (element.categoriecomposant == "garnitures") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){ 
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
            if (element.categoriecomposant == "pâte") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
            if (element.categoriecomposant == "sauces") {
              element.listeIngredients.forEach(cp => {
                if(cp.nomcomposant ==  supplement.nomcomposant){
                  supplement.prixcomposant = cp.prixcomposant;
                }
               
              });
            }
          });
  
        // supplement.nomcomposant
        });*/
        
      }
      if ( element.idtypeproduit == 26) {
        let sommeProduitsOffre=0.00;
        
    
//chercher prix  actuelle
this.allProduits.forEach(combos=>{
 
  if(combos.idproduit == element.idproduit){
   element.prixproduit =combos.prixproduit;   
  }
});
 
      }
      //calcul du nouveau prix des upplèments
      let sommeSupplements =0.00 ;
      if (element.idtypeproduit == 1) {      

        element.listeSupplements.forEach(element => {
          console.log("element is :", element);
          sommeSupplements += (Number(element.prixcomposant));
        //  alert("sommeSupplements :"+(sommeSupplements));
        });
       // alert("test si quantité est number"+ isNumber(Number(element.quantiteproduit)));
     //   alert("PrixtotalSupplement :"+ Number(sommeSupplements) * Number(element.quantiteproduit));
       element.prixtotalSupplement = (Number(sommeSupplements) * Number(element.quantiteproduit));
      }
      if (element.idtypeproduit == 2) {
        //console.log(element.listeSupplements);
        element.listeSupplements.forEach(element => {
          console.log("element is :", element);
          sommeSupplements += Number(element.prixcomposant);
        });
        element.prixtotalSupplement = (Number(sommeSupplements) * Number(element.quantiteproduit));
     
   
      
      }
      panier.totalcom += (element.prixproduit) * element.quantiteproduit +
      (sommeSupplements * element.quantiteproduit);
      panier.listeProduits.push(
        {
          //chercher les prix; si s'est une pizza prix selon taille
          idproduit: element.idproduit,
          nomproduit: element.nomproduit,
          quantiteproduit: Number(element.quantiteproduit),
          imageproduit: element.imageproduit,
          prixproduit: Number(element.prixproduit),
          taille: element.taille,//((this.allPrixTaillePizza[this.pizzataille].taille).substring(0,1)).toUpperCase() ,
          prixtotalSupplement: sommeSupplements *  element.quantiteproduit,//element.prixtotalSupplement * element.quantiteproduit,
          listeSupplements: element.listeSupplements,
          listeARetirer: element.listeARetirer,
          listeIngredients: element.listeIngredients,
          listeOffre:element.listeOffre
        }

      );
    });
    /** */

    console.log("PANIER FINALE EST :" + panier);
    this.router.navigate(['panier']);
    //il faut vider le panier..


  }
  getPrixGarnituresSupplementaire() {
    this.composantProvider.getPrixGarnituresSupplementaire().subscribe(
      //this.composantProvider.getAllComposantsPizza().subscribe(
      res => {
        if (res == false) {
          console.log("Aucune garniture supplémentaire payante ");
        } else {
          this.PrixGarnituresSupplementaire = <[]>res;
          console.log("getPrixGarnituresSupplementaire : ", this.PrixGarnituresSupplementaire);
          // alert("PrixGarnituresSupplementaire :" + this.PrixGarnituresSupplementaire.length);

        }
      }
    );
  }

  getAllComposantsPizza() {
    this.composantProvider.getAllComposants().subscribe(
      //this.composantProvider.getAllComposantsPizza().subscribe(
      res => {
        if (res == false) {
          console.log("Aucun composant à pizza ");
        } else {
          this.allComposants = <[]>res;
          console.log("Composants : ", this.allComposants);
       

          //    this.triIngredients();

        }
      }
    );
  }
  changePrixTaille(index,produit) {

    this.allComposants.forEach(element => {
      //alert("Début prix :"+  element.listeIngredients);
      if (element.categoriecomposant == "garnitures") {
        let t = (produit.taille);// ((this.dataProduit.listeprix[index].taille).substring(0, 1)).toUpperCase();
        let prix: any;
        this.PrixGarnituresSupplementaire.forEach(prixGaR => {
          // if (((prixGaR.taille).substring(0, 1)).toUpperCase() == t) {
          if (prixGaR.taille == t) {
            prix = prixGaR.prix;
          }
        });
        element.listeIngredients.forEach(cp => {
          cp.prixcomposant = prix;
        });
      }
    });
  }
  ////////////
  getProduitsOffres(idtypeproduit){
 
    this.produitProvider.getTypePizzaProduits(idtypeproduit).subscribe(
      res => {
        if (res == false) {
          console.log("Aucun produit de type offre est disponible");
        } else {
          this.allOffres = res;
          this.allOffres=this.allOffres[0].listetypesproduits[0].listeproduits;  
          console.log("all offres :" + JSON.stringify(this.allOffres));
        }
      } //res
    )//sub
  }
  getProduitsCombos(idtypeproduit){
 
    this.produitProvider.getTypePizzaProduits(idtypeproduit).subscribe(
      res => {
        if (res == false) {
          console.log("Aucun produit de type combos est disponible");
        } else {
          this.allProduits = res;
          this.allProduits=this.allProduits[0].listetypesproduits[0].listeproduits;
         // console.log("allCombos :" + JSON.stringify(this.allProduits));
          this.allProduits.forEach(element=>{
            let phrase="";
          //  element.composantsCombos[0].entree.forEach(ingre => {
            //  phrase += phrase + ingre.nomproduitcombos + "ou ";
         //   });
        //  element.composantsCombos[0].nomproduit= phrase;
        //  phrase="";
          });
          console.log("allCombos :" + JSON.stringify(this.allProduits));
        }
      } //res
    )//sub
  }
}
