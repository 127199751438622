/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout traitement des clients.
*  Auteur : Helvitech Dev Team 
*  Date : 25.06.2020
*  ------------------------
*  Description :  Script de type provider, qui met en relation l'applicaiton avec la base de données. 
*                 C'est ici que l'on définit quelles fonctions pour quels scripts. 
*                 Tout traitement de données passera par ici. 
******************************************************************************************************/
//const EnvironnementProd = true;
const EnvironnementProd = false;

/*- - Lien vers le serveur  - -*/
//export let BDD_LIEN_SERVEUR = "http://localhost/rollingpizzaclient/";
// export let BDD_LIEN_SERVEUR = "https://helvitech.com/demos/rollingpizza/";
// export let LIEN_BDD = BDD_LIEN_SERVEUR + "bddtest/";

//export let BDD_LIEN_SERVEUR = "http://localhost/rollingpizza/";

/*- - Lien vers la base de données  - -*/
//export let BDD_LIEN_SERVEUR = "https://helvitech.com/demos/restaurant/";
//export let LIEN_BDD = BDD_LIEN_SERVEUR + "bddtest/";

export let BDD_LIEN_SERVEUR = "https://helvitech.com/prod/rolling/";
export let LIEN_BDD = BDD_LIEN_SERVEUR + "bddClient/";

if (EnvironnementProd) {
    BDD_LIEN_SERVEUR = "https://helvitech.com/prod/rolling/";
    LIEN_BDD = BDD_LIEN_SERVEUR + "bddClient/";
}
else {
    BDD_LIEN_SERVEUR = "https://helvitech.com/demos/rollingpizza/";
    LIEN_BDD = BDD_LIEN_SERVEUR + "bddtest/";
}





/************************************************************************************************
 * Liens vers les différents dossiers de la base de données. Chaque dossier contient un certain *
 *    type de données voulues pour l'application.                                               *
*************************************************************************************************/

/*- - Lien vers le dossier "business_utilisateur", qui contient les données des utilisateur  - -*/
export let LIEN_DONNEES_BUSINESS_UTILISATEUR = LIEN_BDD + "business_utilisateur";


/*- - Lien vers le dossier "shops", qui contient les données des shops ( = business )  - -*/
export let LIEN_DONNEES_SHOPS = LIEN_BDD + "shops/";


/*- - Lien vers le dossier "shops", qui contient les données des produits  - -*/
export let LIEN_DONNEES_PRODUITS = LIEN_BDD + "produits/";


/*- - Lien vers le dossier "evenements", qui contient les données des évènements  - -*/
export let LIEN_DONNEES_EVENEMENTS = LIEN_BDD + "evenements/";


/*- - Lien vers le dossier "offres", qui contient les données des offres  - -*/
export let LIEN_DONNEES_OFFRES = LIEN_BDD + "offres/";


/*- - Lien vers le dossier "clients", qui contient les données des clients  - -*/
export let LIEN_DONNEES_CLIENTS = LIEN_BDD + "clients/";


/*- - Lien vers le dossier "clients", qui contient les données des commandes  - -*/
export let LIEN_DONNEES_COMMANDES = LIEN_BDD + "commandes/";

/*- - Lien vers le dossier "composants", qui contient les données des commandes  - -*/
export let LIEN_DONNEES_COMPOSANTS = LIEN_BDD + "composantsproduits/";

/**
 * 
 * 
 * 
 * 
 * 
 * 
 */
export let LIEN_EMAIL = LIEN_BDD + "password-recovery/find_email.php";

/******************************************************************************************************
 * Liens vers les différents scripts de business_utilisateur. Pour la connexion aux comptes business  *
*******************************************************************************************************/
export let LIEN_GET_UTILISATEUR_CONNEXION = LIEN_DONNEES_BUSINESS_UTILISATEUR + "/get_rolling_utilisateur_connexion.php";



/******************************************************************************************************
 * Liens vers les différents scripts de "shops", selon le traitement définit dans "shops_provider.ts" *
*******************************************************************************************************/
// Aller chercher tous les shops (business)
export let LIEN_GET_INFOS_SHOP = LIEN_DONNEES_SHOPS + "get_all_shops.php";
//Ajouter un nouveau shop/business
export let LIEN_ADD_NEW_SHOP = LIEN_DONNEES_SHOPS + "add_new_shop.php";

//Mettre à jour les informations d'une business (shop)
export let LIEN_UPDATE_BUSINESS = LIEN_DONNEES_SHOPS + "update_profil_business.php";

//Récupérer tous les type de shop existants
export let LIEN_GET_SHOP_ID = LIEN_DONNEES_SHOPS + "get_shop_id.php";

//Récupérer tous les type de shop existants
export let LIEN_GET_ALL_TYPE_SHOP = LIEN_DONNEES_SHOPS + "get_all_type_shop.php";


/**Récupère les horaires du shop selon id */
export let LIEN_GET_HORAIRES_SHOP = LIEN_DONNEES_SHOPS + "get_horaires_id_shop.php";








/************************************************************************************************************
 * Liens vers les différents scripts de "produits", selon le traitement définit dans "produits_provider.ts" *
*************************************************************************************************************/
export let LIEN_GET_ALL_PRODUITS = LIEN_DONNEES_PRODUITS + "get_all_produits.php";
//Pour récupérer tous les produits d'un business, qui est connecté, selon son identifiant (idshop)
export let LIEN_GET_ALL_PRODUITS_BUSINESS = LIEN_DONNEES_PRODUITS + "get_all_produits_business.php";
//Ajouter un nouveau produit à un business, utilisant son identifiant (idshop)
export let LIEN_ADD_NEW_BUSINESS_PRODUIT = LIEN_DONNEES_PRODUITS + "add_new_business_produit.php";

//Ajout d'un nouveau produit 
export let LIEN_ADD_NEW_PRODUIT = LIEN_DONNEES_PRODUITS + "add_new_business_produit.php";
//supprimer un produit selonson id
export let LIEN_DELETE_PRODUIT = LIEN_DONNEES_PRODUITS + "remove_produit_id.php";
//update produit
export let LIEN_UPDATE_PRODUIT = LIEN_DONNEES_PRODUITS + "update_produit_id.php";


//récupérer la liste des catégories de produits : 
export let LIEN_GET_ALL_CATEGORIE_PRODUIT = LIEN_DONNEES_PRODUITS + "get_all_categorie_produits.php";
//Ajouter un nouveau type de produit : 
export let LIEN_ADD_NEW_CATEGORIERODUIT = LIEN_DONNEES_PRODUITS + "add_new_categorieproduit.php";

//récupérer la liste des types de produits : 
export let LIEN_GET_ALL_TYPE_PRODUITS = LIEN_DONNEES_PRODUITS + "get_all_type_produits.php";
//Ajouter un nouveau type de produit : 
export let LIEN_ADD_NEW_TYPEPRODUIT = LIEN_DONNEES_PRODUITS + "add_new_typeproduit.php";


//récupérer la liste des type de produits selon le type de shop du business connecté
export let LIEN_GET_TYPE_PRODUITS_BY_BUSINESS_ID_TYPE_SHOP = LIEN_DONNEES_PRODUITS + "get_type_produit_by_business_nom_type_shop.php";
//Récupérer les infos du type shop, du business connecté : 
export let LIEN_GET_TYPE_SHOP_BUSINESS_CONNECTED = LIEN_DONNEES_PRODUITS + "get_type_shop_business_connected.php";

//Récupérer la liste des types de produit selon la catégorie: 
export let LIEN_GET_TYPEPRODUIT_BY_CATEGORIE = LIEN_DONNEES_PRODUITS + "get_typeproduit_by_categorie.php";


//récupérer la liste des catégorie de produits : 
export let LIEN_GET_ALL_CATEGORIE_PRODUITS = LIEN_DONNEES_PRODUITS + "get_all_categorie_produits.php";
//Ràcupérer les catégorie de produits du type de produit du type de shop du business connecté 
export let LIEN_GET_CATEGORIE_TYPE_SHOP_BUSINESS_CONNECTED = LIEN_DONNEES_PRODUITS + "get_categorie_type_shop_business_connected.php";

//récupérer la liste des sous-catégories de produits : 
export let LIEN_GET_ALL_SOUS_CATEGORIE_PRODUITS = LIEN_DONNEES_PRODUITS + "get_all_sous_categorie_produits.php";

//--------- produits par type de produits -----------//
export let LIEN_GET_TYPEPRODUIT_PIZZA_PRODUITS = LIEN_DONNEES_PRODUITS + "get_all_produits_typeproduit.php";

//
export let LIEN_GET_PRIX_PRODUIT_TAILLE_LIVRAISON = LIEN_DONNEES_PRODUITS + "get_prix_produit_taille_typelivraison.php";

//UPLOAD AND DOWNLOAD PHOTOS
//export let LIEN_FICHIER_UPLOAD = "https://helvitech.com/demos/rollingpizza/bddAdmin/produits/" + "upload" ;
export let LIEN_FICHIER_UPLOAD = BDD_LIEN_SERVEUR + "bddAdmin/produits/" + "upload";
if (EnvironnementProd) {
     LIEN_FICHIER_UPLOAD = BDD_LIEN_SERVEUR + "bddAdmin/produits/" + "upload";
}
else{
    LIEN_FICHIER_UPLOAD = BDD_LIEN_SERVEUR + "bddAdmin/produits/" + "upload";
}
//export let LIEN_FICHIER_UPLOAD = BDD_LIEN_SERVEUR + "/bddAdmin/produits/" + "upload";
export let LIEN_PATH_FILES = LIEN_FICHIER_UPLOAD + "/images/";
export let LIEN_UPLOAD_FILES = LIEN_FICHIER_UPLOAD + "/uploader.php";
export let LIEN_UPLOAD_FILES_PHOTO = LIEN_FICHIER_UPLOAD + "/test1.php";
export let LIEN_UPLOAD_FILES_CHANGE = LIEN_FICHIER_UPLOAD + "/changephoto.php";
export let LIEN_DOWNLOAD_FILES = LIEN_FICHIER_UPLOAD + "/downloader.php";
///////////////////////////

/******************************************************************************************************
 * Liens vers les différents scripts de "composants", 
 * selon le traitement définit dans "composantpizza_provider.ts" *
*******************************************************************************************************/
//Récupérer les composants de pizza pour les afficher dans le détail d'un produit (pizza)
export let LIEN_GET_ALL_COMPOSANTS_PIZZA = LIEN_DONNEES_COMPOSANTS + "get_all_composants_pizza.php";

//Récupérer tous les composants et les ingredients de tous les produits
export let LIEN_GET_ALL_COMPOSANTS = LIEN_DONNEES_COMPOSANTS + "get_all_composants.php";

//récupérer la liste des catégories des composants
export let LIEN_GET_ALL_CATEGORIES_COMPOSANTS = LIEN_DONNEES_COMPOSANTS + "get_all_categories_composants.php";

//Récupérer le détail d'un composant selon son id
export let LIEN_GET_DETAIL_COMPOSANT = LIEN_DONNEES_COMPOSANTS + "get_detail_composant_id.php";
//ADD NEW COMPOSANT
export let LIEN_ADD_NEW_COMPOSANT = LIEN_DONNEES_COMPOSANTS + "add_new_composant.php";
//Update un composant selon son id
export let LIEN_UPDATE_COMPOSANT = LIEN_DONNEES_COMPOSANTS + "update_composant_id.php";

//Supprimer un composant selon son id
export let LIEN_DELETE_COMPOSANT = LIEN_DONNEES_COMPOSANTS + "delete_composant_id.php";

//Récupère  la liste des prix supplèmentaires des garnitures
export let LIEN_GET_ALL_PRIX_GARNITURES_SUPPLEMENTAIRES = LIEN_DONNEES_COMPOSANTS + "get_all_prix_garnitures_supplementaires.php";


/******************************************************************************************************
 * Liens vers les différents scripts de "evenements", 
 * selon le traitement définit dans "evenements_provider.ts" *
*******************************************************************************************************/
//Récupérer tous les événements du business connecté 
export let LIEN_GET_ALL_BUSINESS_EVENTS = LIEN_DONNEES_EVENEMENTS + "get_all_events_business.php";
//Ajouter un nouvel évènement au business connecté : 
export let LIEN_ADD_NEW_BUSINESS_EVENT = LIEN_DONNEES_EVENEMENTS + "add_new_business_event.php";


/******************************************************************************************************
 * Liens vers les différents scripts de "offres", 
 * selon le traitement définit dans "offres_provider.ts" *
*******************************************************************************************************/
//Récupérer tous les événements du business connecté 
export let LIEN_GET_ALL_BUSINESS_OFFRES = LIEN_DONNEES_OFFRES + "get_all_offres_business.php";
//Ajouter un nouvel évènement au business connecté : 
export let LIEN_ADD_NEW_BUSINESS_OFFRE = LIEN_DONNEES_OFFRES + "add_new_business_offre.php";



/******************************************************************************************************
 * Liens vers les différents scripts de "clients", 
 * selon le traitement définit dans "clients_provider.ts" *
*******************************************************************************************************/
// Aller chercher tous les clients
export let LIEN_GET_ALL_CLIENTS = LIEN_DONNEES_CLIENTS + "get_all_clients.php";
//ajout d'un nouveau client
export let LIEN_ADD_NEW_CLIENT = LIEN_DONNEES_CLIENTS + "add_new_client.php";
//mise à jour du profil client 
export let LIEN_UPDATE_PROFIL_CLIENT = LIEN_DONNEES_CLIENTS + "update_profil_client.php";
//Récupérer les informations d'un client 
export let LIEN_GET_CLIENT_ID = LIEN_DONNEES_CLIENTS + "get_client_id.php";


/******************************************************************************************************
 * Liens vers les différents scripts de "commandes_clients", 
 * selon le traitement définit dans "commandesclients_provider.ts" *
*******************************************************************************************************/
// Aller chercher toutes les commandes clients selon un business
export let LIEN_GET_ALL_COMMANDES_CLIENTS_BUSINESS = LIEN_DONNEES_CLIENTS + "get_all_commandes_clients_business.php";
//Récupérer toutes les comandes du client connecté: 
export let LIEN_GET_ALL_COMMANDES_CLIENT = LIEN_DONNEES_COMMANDES + "get_all_commandes_client.php";

//Récupérer les commandes avec le statut "en préparation"
export let LIEN_GET_ALL_COMMANDES_PREPARATION = LIEN_DONNEES_COMMANDES + "get_all_commandes_preparation.php";
//Récupérer les commandes avec le statut "en préparation"
export let LIEN_GET_ALL_COMMANDES_PREPARATION_WITH_PRODUCTS = LIEN_DONNEES_COMMANDES + "get_all_commandes_clients_business_with_produits_preparation.php";


export let LIEN_GET_ALL_COMMANDES_NOUVELLES_WITH_PRODUCTS = LIEN_DONNEES_COMMANDES + "get_all_commandes_clients_business_with_produits_nouvellecommande.php";

export let LIEN_GET_ALL_COMMANDES_PRETARETIRER_WITH_PRODUCTS = LIEN_DONNEES_COMMANDES + "get_all_commandes_clients_business_with_produits_pretaretirer.php";


//Commandes et leur produits - en livraison statut : 
export let LIEN_GET_ALL_COMMANDES_ENLIVRAISON_WITH_PRODUCTS = LIEN_DONNEES_COMMANDES + "get_all_commandes_clients_business_with_produits_enlivraison.php";
//Récupérer les produits d'une commande
export let LIEN_GET_ALL_PRODUITS_COMMANDES = LIEN_DONNEES_COMMANDES + "get_all_produits_commande.php";

//Récupérer les noms des produits d'une commande 
export let LIEN_GET_NAME_PRODUITS_COMMANDE = LIEN_DONNEES_COMMANDES + "get_name_produits_commande.php";

/****Update commande statut scripts *****/

//modifier pour "en préparation"
export let LIEN_UPDATE_COMMANDE_TO_ENPREPARATION = LIEN_DONNEES_COMMANDES + "update_commande_to_enpreparation.php";

//add new commande client 
export let LIEN_ADD_NEW_COMMANDE = LIEN_DONNEES_COMMANDES + "add_new_commande.php";
//add adresse de livraisond de la commande client 
export let LIEN_ADD_DETAIL_LIVRAISON_COMMANDE = LIEN_DONNEES_COMMANDES + "add_new_adresse_livraison_commande.php";

/**Supprimer une commande selon selon id */
export let LIEN_DELETE_COMMANDE_ID = LIEN_DONNEES_COMMANDES + "delete_commande_id.php";

/**Update une commande qui est payée selon id */
export let LIEN_UPDATE_COMMANDE_ISPAID = LIEN_DONNEES_COMMANDES + "upadte_commande_ispaid_id.php";

/**Update une commande qui est payée selon id */
export let LIEN_UPDATE_COMMANDE_ISPAID_STATUT = LIEN_DONNEES_COMMANDES + "upadte_commande_ispaid_id_statut.php";

/**envoyer un email au client avec le résumé de  la commande payée */
export let LIEN_SEND_EMAIL_CLIENT_WITH_COMMANDE_PAID = LIEN_DONNEES_COMMANDES + "send_email_commande_id.php";







