/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, OnInit } from '@angular/core';
import { CommandeClient } from 'src/models/commandesclient_model';
import { CommandesClientProvider } from 'src/providers/commandesclient_provider';
import { Storage } from '@ionic/storage';
import { MenuController } from '@ionic/angular';
import { NavigationExtras, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GlobalVariableService } from '../services/globalVariables.service';
import { CartecreditformPage } from '../cartecreditform/cartecreditform.page';
import { DetailcommandePage } from '../detailcommande/detailcommande.page';

@Component({
  selector: 'app-mescommandes',
  templateUrl: './mescommandes.page.html',
  styleUrls: ['./mescommandes.page.scss'],
})
export class MescommandesPage implements OnInit {
  public panierOld = {
    idclient: null,
    idshop: "1",
    demandeur: 0,
    totalcom: 0.00,
    idtypelivraison: 3,
    heurelivraison: null,
    listeProduits: [{
      idproduit: Number,
      nomproduit: '',
      quantiteproduit: 0,
      prixproduit: 0.00,
      taille: '',
      prixtotalSupplement: 0.00,
      listeIngredients: [{
        idCombos: null,
        nomCombos: null,
        prixCombos: 0.00,
        quantiteCombos: 0
      }],
      listeSupplements: [{
        idcomposant: 0,
        nomcomposant: '',
        categoriecomposant: '',
        prixcomposant: 0.00

      }],
      listeARetirer: [{
        idcomposant: 0,
        nomcomposant: '',
        categoriecomposant: '',
        prixcomposant: 0.00
      }]
    }],

  };
 // allCommandesClient : CommandeClient;
  allCommandesClient: any = [];

  isCommandes =false;
 
  //variable locale pour l'id du client : 
  idclient: any;

  public restaurantName : string;

  constructor(
    private commandeClientProvider: CommandesClientProvider,
    private modalCtrl: ModalController,
    private storage: Storage,
    private menuCtrl: MenuController,
    private router: Router,
    private globalVariables: GlobalVariableService
  ) {
    //indiquer que le menu latéral doit apparaitre au chargement de la page
    this.menuCtrl.enable(true);
    //get the storaged value of the customer ID
    this.getStorageID();

    this.restaurantName = this.globalVariables.restaurantName
  }
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }

 
calculColor(statut :string):string{
  //alert("statut :"+statut);
  if(statut == 'Nouvelle commande'){ return 'secondary';}
  if(statut == 'En préparation'){ return 'warning';}
  if(statut == 'Prêt à retirer'){ return 'success';}
  if(statut == 'En livraison'){ return 'primary';}
  return 'white';

}
  /***********************************
   * [ Storage : données stockées]
   */
  getStorageID() {
    this.idclient =  localStorage.getItem('idclient');
    console.log("ID : ", this.idclient);
    this.getCommandeClient(this.idclient);
    /*this.storage.get('idclient').then((val) => {
      console.log("ID : ", val);
      this.idclient = val;
      this.getCommandeClient(val);
    });*/
  }

  getCommandeClient(idclient: number) {
    this.commandeClientProvider.getAllCommandesClient(idclient).subscribe(    
      res => {
        if (res == false) {
          console.log("Il n'y a aucune commande dans votre compte.");
        } else {
          this.allCommandesClient = res;
          this.isCommandes =true;
          console.log("this.allCommandesClient :" + JSON.stringify(this.allCommandesClient));
          console.log("this.allCommandesClient :" + (this.allCommandesClient));
        }
      }
    );
  }

  /*--- modal detail commande pour commander à nouveau une même commande----
  --------------------------------------------------------------------------
  async goDetailCommande(commande, index) {
    console.log(JSON.stringify(this.allCommandesClient[index]));
    this.panierOld.demandeur = 0;
    this.panierOld.heurelivraison = null;
    this.panierOld.idclient = this.allCommandesClient[index].idclient;
    this.panierOld.idshop = this.allCommandesClient[index].idshop.toString();
    this.panierOld.idtypelivraison = this.allCommandesClient[index].idtypelivraison;
    this.panierOld.totalcom = this.allCommandesClient[index].totalcom;

    this.panierOld.listeProduits = this.allCommandesClient[index].listeProduits;

    console.log(" this.panierOld :" + this.panierOld);
    const modal = await this.modalCtrl.create({
      component: DetailcommandePage,
      componentProps: {
        'panier': this.panierOld
      }

    });
    return await modal.present();
  }

  async goCommande() {
    const modal = await this.modalCtrl.create({
      component: DetailcommandePage,
      componentProps: {
        'panier': this.panierOld
      }

    });
    return await modal.present();
  }
*/

  goInfosCommande(commande: CommandeClient){
    
    let navigationExtras : NavigationExtras = {
      state : {commande : commande}
    }

    this.router.navigate(['../detailcommande'], navigationExtras); 
  }

}
