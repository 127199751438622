/* Projet : Rolling Pizza - Genève
*  Script : Provider pour tout traitement des clients.
*  Auteur : Helvitech Dev Team 
*  Date : 25.06.2020
*  ------------------------
*  Description :  Provider qui va faire le traitement entre les fonction présents dans les ".ts" 
*                    et la base de données, dont les scripts sont défini dans "config_bdd.ts".
******************************************************************************************************/

import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from '@angular/common/http';

/*****************************************************************************
 * Importation des scripts des fonctions concernant le traitement de données *
 *  pour les produits.
******************************************************************************/
import {
    LIEN_GET_ALL_CLIENTS,

    LIEN_GET_UTILISATEUR_CONNEXION,  //lien pour le script de connexion d'utilisateur 

    LIEN_ADD_NEW_CLIENT,
    LIEN_UPDATE_PROFIL_CLIENT
} from "../providers/config_bdd";

import { BehaviorSubject } from 'rxjs';

export class AuthInfo {
    constructor(public $uid: string) { }

isLoggedIn() {
 return !!this.$uid;
}
}

@Injectable()

export class ClientProvider {
    static UNKNOWN_USER = new AuthInfo(null);
    public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(ClientProvider.UNKNOWN_USER);
    constructor(public http: HttpClient) {

    }
   
    //Récupérer tous les shops/business présent dans la base de données
    getAllClients(): any {
        return this.http.post(LIEN_GET_ALL_CLIENTS
            , {
                headers: new HttpHeaders()
                    .set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Access-Control-Allow-Origin', '*')
            }
        );
    }


    /********************************************************
     * Connexion à l'espace privé de chaque client, selon 
     *  un email et un mot de passe unique. 
    *********************************************************/
    getUtilisateurConnexion(emailclient, motdepasseclient): any {
        let myData = JSON.stringify({
            emailclient: emailclient,
            motdepasseclient: motdepasseclient
        });
        return this.http.post(LIEN_GET_UTILISATEUR_CONNEXION, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        });
    }

    /******
     * Ajout d'un nouveau client, suite aux saisies de l'utilisateur
     ****************************************************************/
    addNewClient(nomclient, prenomclient, adresseclient, npaclient, villeclient, paysclient, telephoneclient, emailclient, motdepasseclient,is_active):any{
        let myData = JSON.stringify({
            nomclient: nomclient,
            prenomclient: prenomclient,
            adresseclient: adresseclient,
            npaclient: npaclient,
            villeclient: villeclient,
            paysclient : paysclient, 
            telephoneclient : telephoneclient, 
            emailclient : emailclient,
            motdepasseclient : motdepasseclient,
            is_active : is_active
        });
        console.log("MyData Client Provider Add new client : " + myData);
        return this.http.post(LIEN_ADD_NEW_CLIENT, myData, {
            headers: new HttpHeaders()
                .set('Content-Type', 'application/x-www-form-urlencoded')
                .set('Access-Control-Allow-Origin', '*')
        })
    }
     /******
     * Modification d'un profil du client
     ****************************************************************/
      updateProfilClient(
        idclient, nomclient, prenomclient, 
        adresseclient, npaclient, villeclient, 
        paysclient, telephoneclient, emailclient 
    ):any{

    let myData = JSON.stringify({
        idclient , nomclient , prenomclient,
        adresseclient, npaclient, villeclient,
        paysclient, telephoneclient, emailclient
    });
    console.log("MY DATA TO UPDATE : " + myData);
    return this.http.post(LIEN_UPDATE_PROFIL_CLIENT, myData, {
        headers: new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Access-Control-Allow-Origin', '*')
    })
}

}