/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Stripe as IonicStripe } from '@ionic-native/stripe/ngx';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { PaymentService } from '../api/payment.service';
import { Storage } from '@ionic/storage';
import { panier } from '../app.component';
import { CommandesClientProvider } from 'src/providers/commandesclient_provider';
import { Router } from '@angular/router';
import { MiscService } from '../services/misc.service';
import { ClientProvider } from 'src/providers/clients_provider';
import { Client } from 'src/models/client_model';
import { StripeLivePK, StripeTestPK } from 'src/app/api/payment.service';


//this.router.navigate(['mescommandes']);
declare var Stripe: any;

//mode production
// const stripe = Stripe(StripeLivePK);
// const StripePK = StripeLivePK;

//mode test
const stripe = Stripe(StripeTestPK);
const StripePK = StripeTestPK;

const fonts = [
  {
    cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
  }
];



const elements = stripe.elements({ fonts: fonts, locale: 'auto' });

@Component({
  selector: 'app-cartecreditform',
  templateUrl: './cartecreditform.page.html',
  styleUrls: ['./cartecreditform.page.scss'],
})
export class CartecreditformPage implements OnInit, OnDestroy {

  myStripe: any;
  successfullPaymentMessage: any = "Paiement effectué avec succès";

  total: any;
  //ngmodel from the form : 
  firstname: any;
  lastname: any;
  expiry: any;
  cvc: any;
  number: any;
  name: any;
  expMonth: any;
  expYear: any;

  paymentProcessingMessage = '';
  paymentProcessingTitle = '';
  /*--------------------------------------*/
  // variable locale : 
  idclient: any;
  nomclient: string;
  prenomclient: string;
  adresseclient: string;
  npaclient: number;
  villeclient: string;
  paysclient: string;
  telephoneclient: string;
  emailclient: string;
  /*--------------------------------------*/


  public cardAllInfo: any = {
    idClient: panier.idclient,
    cardNumber: "4242424242424242",
    cvsNumber: "333",
    endMonth: "09",
    endYear: "25",
    creationDate: "2020-08-09",
    creationTime: "11:21",
    lastUseDate: "2020-08-11",
    lastUseTime: "13:29",
    clientNames: "SOUAD BERGUIGA",
    clientAddress: "Rue de Lausanne 36",
    clientState: "GE",
    zipCode: "1201",
    countryCode: "CH",
    idCurrency: "chf",
    telephone: "0787773322",
    email: "gilles.simon@helvitech.com",
    valid: "true"
  }

  public cardinfo: any = {
    number: '4242424242424242',
    // number: this.number,
    //number: this.cardinfo.number,
    expMonth: 12,
    // expMonth: this.expMonth,
    expYear: 2024,
    //expYear: this.expYear,
    //cvc: this.cvcTest, //- Jorge modification 
    name: 'John Smith', // card holder name (optional)
    //name: this.nomclient,
    address_line1: '123 Some Street', // address line 1 (optional)
    address_line2: 'Suite #220', // address line 2 (optional)
    address_city: 'Toronto', // city (optional)
    address_state: 'Ontario', // state/province (optional)
    address_country: 'Canada', // country (optional)
    postalCode: 'L5L5L5', // Postal Code / Zip Code (optional)
    currency: 'CAD' // Three-letter ISO currency code (optional)
  }

  public orderinfo: any = {
    idOrder: "0002",
    idShop: panier.idshop,
    shopName: 'Helvitech',
    idClient: "0023",
    // idClient: this.idclient,
    emailClient: "djameltounsi@gmail.com",
    // emailClient: this.emailclient,
    telephoneClient: "336788454678", // card holder name (optional)
    // telephoneClient: this.telephoneclient,
    clientNames: "Djamel TOUNSI", // address line 1 (optional)
    address: "Route de Geneve 60", // address line 2 (optional)
    zipCode: "74001", // city (optional)
    state: "Ville-La-Grand",
    amount: "0.0",
    idCurrency: 'chf',
    countryCode: 'CH', // state/province (optional)
    idDeliveryType: 'Deliver', // country (optional)
    successful: 'true', // Postal Code / Zip Code (optional)
    idErrorCode: '0000', // Three-letter ISO currency code (optional)
    paymentDate: "2020-08-05",
    paymentTime: "15:55:25"
  }

  clientTempo: Client;
  etage: any;
  codeentre: any;
  commentaire: any;

  // pse
  detailedSuccessfullPaymentMessage: any;
  messages: any;
  placeholders: any;
  masks: any;


  //
  idcom: any;
  paiementSuccesuful = false;
  alreadySaved = false;
  isDisabled = false;
  isPaid = false;
  card: any;
  constructor(
    private ionicStripe: IonicStripe,
    public alertCtrl: AlertController,
    private paymentService: PaymentService,
    private storage: Storage,
    private commandesClientProvider: CommandesClientProvider,
    private modalCtrl: ModalController,
    public router: Router,
    public navParams: NavParams,
    public miscService : MiscService
  ) {
    console.log(panier);
    this.total = MiscService.roundTwoDecimals(panier.totalcom);
    this.orderinfo.amount = MiscService.roundTwoDecimals(this.total);
    //Récupérer les données stockées localement concernant le client actuellement connecté.
    this.getStorageID();
    this.getStorageNomClient();
    this.getStoragePrenomClient();
    this.getStorageAdresseClient();
    this.getStorageNpaClient();
    this.getStorageVilleClient();
    this.getStoragePaysClient();
    this.getStorageTelephoneClient();
    this.getStorageEmailClient();
    //récupérer le nameshop
    this.getNameShop(panier.idshop);
    this.clientTempo = this.navParams.get('clientTempo');
    this.codeentre = this.navParams.get('codeentree');
    this.etage = this.navParams.get('etage');
    this.commentaire = this.navParams.get('commentaire');

    this.idcom = this.navParams.get('idcomPanier');
    if (this.idcom == null) {
      this.idcom = localStorage.getItem('idcom');
    }



    // localStorage.removeItem('idcom');
    // this.saveCommande(); //enregistrer la commande dans la bd

   
  }


  



  ngOnDestroy(): void {
    this.card.destroy();
    if (this.paiementSuccesuful == false) {
      if (this.idcom != null) {
        console.log("dismissModal test idcom!=null => annuler commande");
        this.updateCommandeIsPaidStatut(this.idcom, -2);
        //this.annulerCommande(this.idcom);
      }
      localStorage.removeItem('idcom');
      this.viderPanier();
      
      this.modalCtrl.dismiss();
      this.router.navigate(['/tabs/menu']);
    }
    else {
      //upadte commande avec isPAid=1
      this.updateCommandeIsPaid(this.idcom);
      localStorage.removeItem('idcom');
      this.viderPanier();
      this.modalCtrl.dismiss();
      this.router.navigate(['mescommandes']);
    }
    console.log("ngOnDestroy est appelé");

    //  throw new Error('Method not implemented.');
  }
  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    const confirm =  this.confirmationAlert(
      'Do you really want to delete the entry?'
    );
    if (confirm) {
      console.log('Deleted');
      this.ngOnDestroy();
    } else {
      console.log('Canceled');
      window.onbeforeunload = null;
      //window.removeEventListener("BeforeUnloadEvent",this.presentAlertConfirmerSuppressionCommande);
    }
   // this.presentAlertConfirmerSuppressionCommande(this.idcom);
  
 
      // Your logic on beforeunload
     
      
  }
  private  confirmationAlert(message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });
    let alert =  this.alertCtrl.create({
      header: 'Confirmation',
      message,
      backdropDismiss: false,
      buttons: [
        {
          text: 'No',
          handler: () => resolveFunction(false)
        },
        {
          text: 'Yes',
          handler: () => resolveFunction(true)
        }
      ]
    });
     alert.then(res=>{
       res.present();
     });
    return promise;
  }
  async presentAlertConfirmerSuppressionCommande(idcom) {
    let entete :any;
  
        const alert = await this.alertCtrl.create({
          cssClass: 'my-custom-class',
          header: 'Quitter RollingPizza' ,
          subHeader: 'Voulez-vous annuler  la commande n° ' + idcom,
    
          buttons: [
            {
              text: 'Annuler',
              role: 'cancel',
              handler: () => { //takes the data 
                window.onbeforeunload = null;
                window.removeEventListener("BeforeUnloadEvent",this.presentAlertConfirmerSuppressionCommande);
               // this.updateCommandeIsPaidStatut(idcom,-2);
                   
              }
            },
            {
              text: 'OK',
              handler: () => { //takes the data 
                this.ngOnDestroy();
               // this.updateCommandeIsPaidStatut(idcom,-2);
                   
              }
            }
          ]
        });
    
        await alert.present();
      }
  private loadCardFormArchive() {
    // Your Stripe public key
    // const stripe = Stripe('pk_test_123456123456123456');
    const stripe = Stripe(StripePK);
    // this.stripe.setPublishableKey('pk_test_51H56BFAXWVcdYU4ymVxHQ7PxjMGDyVdtwIDCs6arTPQh5JZ7kMabw17q43q4mSJ7uQ7zyVIdCOlh9ujFH5sswLn70096fIhKoU');
    this.myStripe = stripe;

    // Create `card` element that will watch for updates
    // and display error messages
    const elements = stripe.elements();
    console.log("Elements: ", elements);
    const card = elements.create('card');
    card.mount('#card-element');
    console.log("Card elements: ", card);

    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Listen for form submission, process the form with Stripe,
    // and get the 
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      this.payWithStripeArchive(card);
      /*
      stripe.createToken(card).then(result => {
        if (result.error) {
          console.log('Error creating payment method.');
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          console.log('Token acquired!');
          console.log(result.token);
          console.log(result.token.id);
        }
      });
      */
    });
  }
  updateCommandeIsPaid(idcom) {
    this.commandesClientProvider.updateCommandeIsPaid(idcom).subscribe(
      res => {
        console.log("la commande est marquée comme payée !!");
      }
    );
  }
  //update Statut paiement de la commade
  updateCommandeIsPaidStatut(idcom, statutPaid) {
    this.commandesClientProvider.updateCommandeIsPaidStatut(idcom, statutPaid).subscribe(
      res => {
        console.log("le statut du paiement de la commande est changée !!");
        //this.refresh();
      }
    );
  }
  ngOnInit() {
    this.idcom = this.navParams.get('idcomPanier');
    if (this.idcom == null) {
      this.idcom = localStorage.getItem('idcom');
    }
    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: '16px',
        color: '#32325d',
      },
    };

    const style2 = {
      iconStyle: 'solid',
      style: {
        base: {
          iconColor: '#c4f0ff',
          color: '#fff',
          fontWeight: 500,
          fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',

          ':-webkit-autofill': {
            color: '#fce883',
          },
          '::placeholder': {
            color: '#87BBFD',
          },
        },
        invalid: {
          iconColor: '#FFC7EE',
          color: '#FFC7EE',
        },
      },
    }

    // Create an instance of the card Element.
    this.card = elements?.create('card', { hidePostalCode: true, style2 });

    // Add an instance of the card Element into the `card-element` <div>.
    this.card.mount('#card-element');


    // Create a token or display an error when the form is submitted.
    const form = document.getElementById('payment-form');
    // $('#payment-button').button('enable');
    const butonPayment = <HTMLInputElement>document.getElementById('payment-button');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      butonPayment.disabled = true;
      // alert("Début : butonPayment.disabled = true : "+butonPayment.disabled);

      //$('#payment-button').button('disable');
      const { token, error } = await stripe.createToken(this.card);

      if (error) {
        butonPayment.disabled = false;
        // alert("ERROR token : butonPayment.disabled = false : "+butonPayment.disabled);
        //  $('#payment-button').button('enable');
        //  alert("Erreur :"+error.message);
        this.presentAlert("Erreur paiement", error.message);
        // Inform the customer that there was an error.
        // const errorElement = document.getElementById('card-errors');
        // errorElement.textContent = error.message;
      } else {
        // Send the token to your server.
        stripeTokenHandler(token);
      }
    });

    const stripeTokenHandler = (token) => {
      // alert ("Check card data: " + token.id);
      this.paymentProcessingMessage = 'Info';
      this.paymentProcessingMessage = 'Traitement Paiement en cours';
      console.log(token);

      // Insert the token ID into the form so it gets submitted to the server
      const form = document.getElementById('payment-form');
      const hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', 'stripeToken');
      hiddenInput.setAttribute('value', token.id);
      form.appendChild(hiddenInput);

      // Submit the form
      // form.submit();
      this.processingStripePayment(token, this.card);
    }

    // this.loadCardForm();
    // this.findClientCardDetails(panier.idclient);
    // this.initCardForm();
  }

  processingStripePayment(token: any, creditCard: any) {

    this.orderinfo.amount = this.total;
    // this.orderinfo.amount = this.orderinfo.amount.toFixed(2);


    this.remplirInfo();  //remplir les infos pour le paiement


    console.log("######this.idcom = ", this.idcom);

    console.log("stripeToken = ", token.id);
    console.log("localStorage.getItem('idcom') = ", localStorage.getItem('idcom'));
    let data = {
      "idOrder": this.idcom,//this.orderinfo.idOrder,//this.idcom ,//localStorage.getItem('idcom'),
      "idShop": this.orderinfo.idShop,
      "shopName": this.orderinfo.shopName,
      "idClient": this.orderinfo.idClient,
      "emailClient": this.orderinfo.emailClient,
      "telephoneClient": this.orderinfo.telephoneClient,
      "clientNames": this.orderinfo.clientNames,
      "address": this.orderinfo.address,
      "state": this.orderinfo.state,
      "zipCode": this.orderinfo.zipCode,
      "countryCode": this.orderinfo.countryCode,
      "amount": this.orderinfo.amount,
      "idCurrency": this.orderinfo.idCurrency,
      "idDeliveryType": this.orderinfo.idDeliveryType,
      "successful": "true",
      "idErrorCode": "0000",
      "paymentDate": "2030-01-01",
      "paymentTime": "12:00:00",
      "card": creditCard,
      "cardinfo": this.cardinfo,
      "cardAllInfo": this.cardAllInfo,
      "stripeToken": token.id
    };
    console.log("Card avant payment = ", data);

    console.log("######this.idcom  APRES SAVE = ", this.idcom);

    console.log("ocalStorage.getItem('idcom') = ", localStorage.getItem('idcom'));
    // Faire un appel asynchrone ...
    this.paiementSuccesuful = false;

    this.paymentService.create(data).subscribe(
      result => {
        console.log("Result OK = ", result);
        console.log("Card infos ", creditCard);
        this.paymentProcessingTitle = "Message"
        this.paymentProcessingMessage = "Paiement terminé avec succès";
        this.presentAlert("Succès", "Paiement fait avec succès");
        this.paiementSuccesuful = true;
        //Envoyer un email avec le résumé de la commande
        this.commandesClientProvider.sendEmailClientCommandeConfirmation(this.idcom).subscribe();
       // localStorage.removeItem('idcom');
       // this.viderPanier();
        this.dismissModal();
        //sauvergarde de la carte
        //  if (this.saveCard = true) {
        //  this.paymentService.createCard(this.cardAllInfo).subscribe(
        //   result => {
        //    console.log("Client Payment Card saved successfully = ", result);
        // this.presentAlert("Payment OK", "Payment done successfully");
        // this.paymentProcessingTitle = "Message"
        // this.paymentProcessingMessage = "Paiement terminé avec succès";
        // this.presentAlert("Succès", "Paiement fait avec succès");
        //    },
        //   error => {
        //    console.log("Result Error= ", error);

        // this.paymentProcessingTitle = "Message"
        // this.paymentProcessingMessage = "Paiement terminé avec ERREUR";
        // this.presentAlert("Erreur", error.message);
        //  });
        //     }

      },
      error => {
        //$('#payment-button').button('enable');
        const butonPayment = <HTMLInputElement>document.getElementById('payment-button');
        butonPayment.disabled = false;
        //alert("ERROR : butonPayment.disabled = false : "+butonPayment.disabled);
        console.log("Result Error= ", error);
        //this.paymentProcessingTitle = "Message"
        //  this.paymentProcessingMessage = "Paiement terminé avec ERREUR";
        // this.presentAlert("Erreur", error.message);
        this.presentAlert("Erreur", "Votre paiment n'est pas passé. Veuillez vérifier. ");
        //Supprimer la commande
        // this.annulerCommande(this.idcom);
        // this.dismissModal();
      });

    //fermer le modal après un paiement : 
    // this.dismissModal();
  }
//Envoyerun email avec le résumé de la commande
EvoyerEmailResume(){
  this.commandesClientProvider.sendEmailClientCommandeConfirmation(this.idcom).subscribe();

}
  viderPanier() {
    panier.listeProduits.splice(0, panier.listeProduits.length)
    panier.totalcom = 0.00;
    panier.demandeur = 0;
    panier.idtypelivraison = 3;
  }

  annulerCommande(idcom) {
    this.commandesClientProvider.deleteCommandeId(idcom).subscribe(
      res => {
        if (res == false) {
          console.log("Commande n'est pas annulée ");
        } else {
          localStorage.removeItem('idcom');
          console.log("Commande est annulée avec succés ");
        }
      }
    );
  }
  initCardForm() {

    // Your Stripe public key
    const stripe = Stripe(StripePK);

    // Create `card` element that will watch for updates
    // and display error messages
    const elements = stripe.elements();
    var style = {
      base: {
        'lineHeight': '1.35',
        'fontSize': '1.11rem',
        'color': '#495057',
        'fontFamily': 'apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif'
      }
    };

    const card = elements.create('card', { hidePostalCode: true });

    card.mount('#card-element');
    card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Listen for form submission, process the form with Stripe,
    // and get the 
    const paymentForm = document.getElementById('payment-form');
    paymentForm.addEventListener('submit', event => {
      event.preventDefault();
      stripe.createToken(card).then(result => {
        if (result.error) {
          console.log('Error creating payment method.');
          const errorElement = document.getElementById('card-errors');
          errorElement.textContent = result.error.message;
        } else {
          // At this point, you should send the token ID
          // to your server so it can attach
          // the payment source to a customer
          console.log('Token acquired!');
          console.log(result.token);
          console.log(result.token.id);
        }
      });
    });

  }

  //trouver les détails de la carte de crédit d'un client via son identifiant : 
  findClientCardDetails(idClient: any) {
    const data = { idClient: idClient };
    this.paymentService.findCardsByIdClient(data).subscribe(
      result => {
        // this.presentAlert("Card OK", "Card Data Access Processed successfully");

        if (result) {
          console.log("Client Card Data Found = ", result);
          // this.saveCardDisabled = true;

          if (Array.isArray(result)) {
            if (result.length > 0) {
              this.number = result[0].cardNumber;
              this.cvc = result[0].cvsNumber;
              this.expMonth = result[0].endMonth;
              this.expYear = result[0].endYear;
              this.name = result[0].clientNames;
            }
            else {
              console.log("New Client - Card Data Not available = ", result);
            }
          }
          else {
            this.number = result.cardNumber;
            this.cvc = result.cvsNumber;
            this.expMonth = result.endMonth;
            this.expYear = result.endYear;
            this.name = result.clientNames;
          }
        }
        else {
          console.log("New Client - No Card Data Found = ", result);
        }
      },
      error => {
        console.log("Result Error= ", error);
        this.presentAlert("Payment Failed", error);
      });
  }


  //récupérer le nameshop
  getNameShop(idshop) {
    this.commandesClientProvider.getShopID(parseInt(idshop)).subscribe(
      res => {
        console.log("shop name :" + res.nomshop);
        this.orderinfo.shopName = res.nomshop;
        // alert(this.orderinfo.shopName);
      }
    );
  }
  /***********************************
   * [ Storage : données stockées]
   */
  getStorageID() {
    this.idclient = localStorage.getItem('idclient');
    console.log("ID : ", this.idclient);
    /* this.storage.get('idclient').then((val) => {
       console.log("ID : ", val);
       this.idclient = val;
     });*/
  }

  getStorageNomClient() {
    this.storage.get('nomclient').then((val) => {
      console.log('Nom client : ', val);
      this.nomclient = val;
    });
  }

  getStoragePrenomClient() {
    this.storage.get('prenomclient').then((val) => {
      console.log('Prenom client  : ', val);
      this.prenomclient = val;
    });
  }

  getStorageAdresseClient() {
    this.storage.get('adresseclient').then((val) => {
      console.log('Adresse client : ', val);
      this.adresseclient = val;
    });
  }

  getStorageNpaClient() {
    this.storage.get('npaclient').then((val) => {
      console.log('NPA client : ', val);
      this.npaclient = val;
    });
  }

  getStorageVilleClient() {
    this.storage.get('villeclient').then((val) => {
      console.log('Ville client : ', val);
      this.villeclient = val;
    });
  }

  getStoragePaysClient() {
    this.storage.get('paysclient').then((val) => {
      console.log('Pays client : ', val);
      this.paysclient = val;
    });
  }

  getStorageTelephoneClient() {
    this.storage.get('telephoneclient').then((val) => {
      console.log('Téléphone client : ', val);
      this.telephoneclient = val;
    });
  }

  getStorageEmailClient() {
    this.storage.get('emailclient').then((val) => {
      console.log('Email client : ', val);
      this.emailclient = val;
    });
  }

  /***** END LOCAL VARIABLES *******/
  remplirInfo() {
    this.idcom = localStorage.getItem('idcom');
    this.orderinfo.idOrder = this.idcom;//"0002";//le retour idcom de insertcommande
    //alert("this.orderinfo.idOrder in remplir : "+this.orderinfo.idOrder);
    //alert("localStorage.getItem('idcom')in remplir : "+localStorage.getItem('idcom'));
    this.orderinfo.idShop = panier.idshop;
    this.getNameShop(panier.idshop);
    this.orderinfo.idClient = panier.idclient;
    this.orderinfo.emailClient = this.emailclient;
    this.orderinfo.telephoneClient = this.telephoneclient; // card holder name (optional)
    this.orderinfo.clientNames = this.nomclient + " " + this.prenomclient; // address line 1 (optional)
    this.orderinfo.address = this.adresseclient; // address line 2 (optional)
    this.orderinfo.zipCode = this.npaclient;// city (optional)
    this.orderinfo.state = this.villeclient;

    this.orderinfo.idCurrency = 'chf';
    this.orderinfo.countryCode = 'CH'; // state/province (optional)
    this.orderinfo.idDeliveryType = panier.idtypelivraison;


    //////////////

    this.cardAllInfo.idClient = panier.idclient;
    this.cardAllInfo.cardNumber = "4242424242424242";
    this.cardAllInfo.cvsNumber = "333";
    this.cardAllInfo.endMonth = "09";
    this.cardAllInfo.endYear = "25";
    this.cardAllInfo.creationDate = "2020-08-09";
    this.cardAllInfo.creationTime = "11:21";
    this.cardAllInfo.lastUseDate = "2020-08-11";
    this.cardAllInfo.lastUseTime = "13:29";
    this.cardAllInfo.clientNames = "Souad";
    this.cardAllInfo.clientAddress = this.adresseclient;
    this.cardAllInfo.clientState = this.villeclient;
    this.cardAllInfo.zipCode = this.npaclient;
    this.cardAllInfo.countryCode = "CH";
    this.cardAllInfo.idCurrency = "chf";
    this.cardAllInfo.telephone = this.telephoneclient;
    this.cardAllInfo.email = this.emailclient;
    this.cardAllInfo.valid = "true";
  }

  saveCommande() {
    // alert("ICI save commande : this.alreadySaved!!!!  "+ this.alreadySaved);
    //console.log("Le panier est :", panier);
    if (this.alreadySaved == true) { return; }
    else {
      //alert("Sauvegarde commande dans la BD est autorisé");
      this.commandesClientProvider.addPanier(panier).subscribe(
        res => {
          console.log("****************saveCommande : RES = ", res);
          if (res < 1) {
            console.log("Aucun composant à pizza ");
            this.alreadySaved == false;
          } else {
            localStorage.removeItem('idcom')
            console.log("saveCommande : idcom retour ", res);
            panier.listeProduits.splice(0, panier.listeProduits.length)
            panier.totalcom = 0.00;
            panier.demandeur = 0;
            panier.idtypelivraison = 3;
            this.alreadySaved = true;
            //this.total = 0.00;
            // this.idcom = res;
            this.idcom = res;
            localStorage.setItem('idcom', res);
            localStorage.setItem('idcom', res);
            this.orderinfo.idOrder = res;//localStorage.getItem('idcom');      
            // this.remplirInfo();           
            //enregistrer les détails de livraison
            this.saveDetailslivraison(res, this.clientTempo, this.etage, this.codeentre, this.commentaire);
          }
        }
      );
    }
  }

  //save details de livaison
  saveDetailslivraison(idcom, clientTempo, etage, codeentre, commentaire) {
    this.commandesClientProvider.addAddresseLivraison(idcom, clientTempo, etage, codeentre, commentaire).subscribe(
      res => {
        if (res == false) {
          console.log("Aucun composant à pizza ");
        } else {
          console.log("Save adresse de livraison :  ", res);
        }
      }
    );
  }
  //save card
  saveCard: any;
  saveCardDisabled: Boolean = false;

  payWithStripeArchive(creditCard?: any) {
    //enregistrer dans la bd la commande => avoir comme retour idcommande remplir this.orderinfo avec les infos du panier et du client

    this.orderinfo.amount = this.total;
    // this.orderinfo.amount = this.orderinfo.amount.toFixed(2);

    this.saveCommande(); //enregistrer la commande dans la bd



    if (isNaN(this.orderinfo.amount)) {
      this.presentAlert("Invalid amount: ", this.orderinfo.amount);
      return;
    }
    if ((0.0001 + this.orderinfo.amount) < 1.0) {
      this.presentAlert("Too small amount: ", this.orderinfo.amount);
      return;
    }



    if (creditCard == null) {
      creditCard = {

        number: this.number,
        expMonth: this.expMonth,
        expYear: this.expYear,
        cvc: this.cvc
      }
    }

    this.ionicStripe.setPublishableKey('pk_test_51H56BFAXWVcdYU4ymVxHQ7PxjMGDyVdtwIDCs6arTPQh5JZ7kMabw17q43q4mSJ7uQ7zyVIdCOlh9ujFH5sswLn70096fIhKoU');

    this.ionicStripe.createCardToken(creditCard)
      //this.myStripe.createToken(creditCard)
      .then(token => {
        console.log("stripeToken = ", token.id);

        let data = {
          "idOrder": this.orderinfo.idOrder,
          "idShop": this.orderinfo.idShop,
          "shopName": this.orderinfo.shopName,
          /* --- patrice stripe*/
          "idClient": this.orderinfo.idClient,
          "emailClient": this.orderinfo.emailClient,
          "telephoneClient": this.orderinfo.telephoneClient,
          "clientNames": this.orderinfo.clientNames,
          "address": this.orderinfo.address,
          "state": this.orderinfo.state,
          "zipCode": this.orderinfo.zipCode,


          /* Jorge modifs ---------------------------
          "idClient": this.idclient,
          "emailClient": this.emailclient,
          "telephoneClient": this.telephoneclient,
          "clientNames": this.nomclient + ' ' + this.prenomclient,
          "address": this.adresseclient,
          "state": this.villeclient,
          "zipCode": this.npaclient,
          */ //------------------------------------------
          "countryCode": this.orderinfo.countryCode,
          "amount": this.orderinfo.amount,
          "idCurrency": this.orderinfo.idCurrency,
          "idDeliveryType": this.orderinfo.idDeliveryType,
          "successful": "true",
          "idErrorCode": "0000",
          "paymentDate": "2030-01-01",
          "paymentTime": "12:00:00",
          "card": creditCard,
          "cardinfo": this.cardinfo,
          "cardAllInfo": this.cardAllInfo,
          "stripeToken": token.id
        };

        // Faire un appel asynchrone ...
        this.paymentService.create(data).subscribe(
          result => {
            console.log("Result OK = ", result);
            console.log("Card infos ", creditCard);

            //sauvergarde de la carte
            if (this.saveCard = true) {
              this.paymentService.createCard(this.cardAllInfo).subscribe(
                result => {
                  console.log("Client Payment Card saved successfully = ", result);
                  // this.presentAlert("Payment OK", "Payment done successfully");
                },
                error => {
                  console.log("Result Error= ", error);
                  this.presentAlert("Payment Failed", error);
                });
            }


          },
          error => {
            console.log("Result Error= ", error);
          },
          () => {
            console.log("Result Unknown result ");
          });

        this.presentAlert("Succès", "Paiement fait avec succès");


      })
      .catch(error => {
        console.error(error);
        this.presentAlert("Echec", error);
      });

    // Find clients by names

    //fermer le modal après un paiement : 
    this.dismissModal();

  }

  async presentAlert(title: string, message: string) {
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Paiement',
      subHeader: title,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
  }

  dismissModal() {
    console.log("dismissModal est appelé");
    if (this.paiementSuccesuful == false) {
      if (this.idcom != null) {
        console.log("dismissModal test idcom!=null => annuler commande");
        this.updateCommandeIsPaidStatut(this.idcom, -2);
        //this.annulerCommande(this.idcom);
      }
      localStorage.removeItem('idcom');
      this.viderPanier();
      this.modalCtrl.dismiss();
      this.router.navigate(['/']);
    }
    else {
      localStorage.removeItem('idcom');
      this.viderPanier();
      this.modalCtrl.dismiss();
      this.router.navigate(['mescommandes']);
    }


  }

  async testPaymentService() {

    let data = {
      "idOrder": this.orderinfo.idOrder,
      "idShop": this.orderinfo.idShop,
      "shopName": this.orderinfo.shopName,
      "idClient": this.orderinfo.idClient,
      "emailClient": this.orderinfo.emailClient,
      "telephoneClient": this.orderinfo.telephoneClient,
      "clientNames": this.orderinfo.clientNames,
      "address": this.orderinfo.address,
      "state": this.orderinfo.state,
      "zipCode": this.orderinfo.zipCode,
      "countryCode": this.orderinfo.countryCode,
      "amount": this.orderinfo.amount,
      "idCurrency": this.orderinfo.idCurrency,
      "idDeliveryType": this.orderinfo.idDeliveryType,
      "successful": "true",
      "idErrorCode": "0000",
      "paymentDate": "2030-01-01",
      "paymentTime": "12:00:00",
      "card": {
        number: this.cardinfo.number,
        expMonth: this.cardinfo.expMonth,
        expYear: this.cardinfo.expYear,
        cvc: this.cardinfo.cvc
      },
      "cardinfo": this.cardinfo,
      "cardAllInfo": this.cardAllInfo,
      "stripeToken": 'token.id'
    };

    // Faire un appel asynchrone ...
    // Find clients by names
    this.paymentService.findPaymentsByClientNames(data).subscribe(
      result => {
        console.log("Client Payment Data Found = ", result);
        this.presentAlert("Payment OK", "Payment done successfully");
      },
      error => {
        console.log("Result Error= ", error);
        this.presentAlert("Echec", error);
      });

    //
    this.paymentService.findPaymentsByIdClient(data).subscribe(
      result => {
        console.log("Payments By IdClient Found = ", result);
        this.presentAlert("Success", "Payment successfully found");
      },
      error => {
        console.log("Error payment Registration = ", error);
        this.presentAlert("Echec ", error);
      }
    );

    //
    this.paymentService.findPaymentsByEmailClient(data).subscribe(
      result => {
        console.log("Payments By emailClient Found = ", result);
        this.presentAlert("Payment OK", "Payment successfully found");
      },
      error => {
        console.log("Error payment Registration = ", error);
        this.presentAlert("Payment Failed ", error);
      }
    );


    this.paymentService.findCardsByClientNames(data).subscribe(
      result => {
        console.log("Client Cards Data Found = ", result);
        this.presentAlert("Card OK", "Card retrieved successfully");
      },
      error => {
        console.log("Result Error= ", error);
        this.presentAlert("Cards retrieval Failed", error);
      });

    //
    this.paymentService.findCardsByIdClient(data).subscribe(
      result => {
        console.log("cards By IdClient Found = ", result);
        this.presentAlert("Cards OK", "Cards successfully retrieved");
      },
      error => {
        console.log("Error card retrieval = ", error);
        this.presentAlert("Card retrieval Failed ", error);
      }
    );

    //
    this.paymentService.findCardsByEmailClient(data).subscribe(
      result => {
        console.log("Cards By emailClient Found = ", result);
        this.presentAlert("Cards OK", "Card successfully retrieved");
      },
      error => {
        console.log("Error card retrieval = ", error);
        this.presentAlert("Card retrieval Failed ", error);
      }
    );
  }

}
