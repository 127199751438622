import { Injectable } from '@angular/core';





@Injectable({
  providedIn: 'root'
})


export class GlobalVariableService {

public restaurantName = "Saveur Pizza"

 
  constructor() { }

 

 

   
}
