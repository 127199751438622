import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'detailproduit',
    loadChildren: () => import('./produits/detailproduit/detailproduit.module').then( m => m.DetailproduitPageModule)
  },
  {
    path: 'panier',
    loadChildren: () => import('./panier/panier.module').then( m => m.PanierPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'newlogin',
    loadChildren: () => import('./login/newlogin/newlogin.module').then( m => m.NewloginPageModule)
  },
  {
    path: 'profil',
    loadChildren: () => import('./profil/profil.module').then( m => m.ProfilPageModule)
  },
  {
    path: 'payment-success',
    loadChildren: () => import('./payment-success/payment-success.module').then( m => m.PaymentSuccessPageModule)
  },
  {
    path: 'payment-cancel',
    loadChildren: () => import('./payment-cancel/payment-cancel.module').then (m => m.PaymentCancelPageModule)
  },

  {
    path: 'mescommandes',
    loadChildren: () => import('./mescommandes/mescommandes.module').then( m => m.MescommandesPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'confirmercommande',
    loadChildren: () => import('./confirmercommande/confirmercommande.module').then( m => m.ConfirmercommandePageModule)
  },
  {
    path: 'payercommande',
    loadChildren: () => import('./payercommande/payercommande.module').then( m => m.PayercommandePageModule)
  },
  {
    path: 'cartecreditform',
    loadChildren: () => import('./cartecreditform/cartecreditform.module').then( m => m.CartecreditformPageModule)
  },
  {
    path: 'detailcommande',
    loadChildren: () => import('./detailcommande/detailcommande.module').then( m => m.DetailcommandePageModule)
  },
  {
    path: 'updateprofil',
    loadChildren: () => import('./updateprofil/updateprofil.module').then( m => m.UpdateprofilPageModule)
  },
  {
    path: 'shops',
    loadChildren: () => import('./shops/shops.module').then( m => m.ShopsPageModule)
  },
  {
    path: 'menu-mobile',
    loadChildren: () => import('./menu-mobile/menu-mobile.module').then( m => m.MenuMobilePageModule)
  },
  {
    path: 'menu-main-products',
    loadChildren: () => import('./menu-main-products/menu-main-products.module').then( m => m.MenuMainProductsPageModule)
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
