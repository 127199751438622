import { Component, HostListener } from '@angular/core';


import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { Subscription } from 'rxjs';
import { AuthInfo, ClientProvider } from 'src/providers/clients_provider';
import { Router } from '@angular/router';
import { ScreensizeService } from './services/screensize.service';

/*export const panier = {
  idclient: "1",
  idshop: "1",
  totalcom: 0.00,
  idtypelivraison: 1,
  listeProduits: [{
    idproduit: Number,
    nomproduit: String,
    quantiteproduit: 0,
    prixproduit: 0.00,
    prixtotalSupplement: 0.00,
    listeSupplements: [{
      nomcomposant: '',
      prixcomposant: 0.00
    }]
  }],
};*/

export const MINIMUM_COMMANDE_VALUE = 22.90;

export const panier = {
  idclient: null,
  idshop: null,
  demandeur: 0,
  totalcom: 0.00,
  idtypelivraison: 3,
  heurelivraison: null,
  listeProduits: [{
    idproduit: Number,
    nomproduit: String,
    imageproduit: String,
    quantiteproduit: 0,
    prixproduit: 0.00,
    taille: '',
    prixtotalSupplement: 0.00,
    listeIngredients: [{
      idCombos: null,
      nomCombos: null,
      prixCombos: 0.00,
      quantiteCombos: 0
    }],
    listeSupplements: [{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00

    }],
    listeARetirer: [{
      idcomposant: Number,
      nomcomposant: '',
      categoriecomposant: '',
      prixcomposant: 0.00
    }],
    listeOffre: [{
      idproduitOffre: Number,
      nomproduitOffre: '',
      taille: '',
      prixproduitOffre: 0.00,
      quantiteOffre: Number,
      listeprix: []
    }]
  }],

};


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public selectedIndex = 0;
  public appPages = [];
  /*
  public appPages = [
    {
      title: 'Home',
      url: 'tabs/home',
      icon: 'mail'
    },
    {
      title: 'Menu Rolling',
      url: 'tabs/menu',
      icon: 'paper-plane'
    },
    {
      title: 'Login Page',
      url: '../../login',
      icon: 'person'
    }
  ];
  */

  //variable pour stocker l'identifiant du client connecté 
  idclient: any;
  nomclient: string;
  prenomclient: string;
  userID: string;
  isDesktop: boolean;
  cartItemCount:number;

  private observableVar: Subscription; //rafraichir la page

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public clientProvider: ClientProvider,
    private router: Router,
    private screensizeService: ScreensizeService
  ) {
    this.initializeApp();

    this.screensizeService.isDesktopView().subscribe(isDesktop => {
      if (this.isDesktop && !isDesktop) {
        // Reload because our routing is out of place
        window.location.reload();
      }
 
      this.isDesktop = isDesktop;
    });
  }



  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.screensizeService.onResize(this.platform.width());
    });
  }

  @HostListener('window:resize', ['$event'])
  private onResize(event) {
    this.screensizeService.onResize(event.target.innerWidth);
  }

  ngOnInit() {
    const path = window.location.pathname.split('home')[1];
    if (path !== undefined) {
      this.selectedIndex = this.appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    this.getProfile();
    // console.log("ngOnInit started : aapcomponenet. ");
    //update side menu   
    this.clientProvider.authInfo$.subscribe((data) => {
      // console.log('userData',data);
      this.userID = data.$uid;
      // console.log('Userd id from behaviour subject', this.userID);
      this.loadProfile();
      // console.log("called get profile after behaviour subject");
    });
  }

 

  ionViewWillEnter() {
    this.getProfile();
   // console.log("IonViewWillEnter done. ");
  }

  ionViewDidLoad() {
    this.getProfile();
  }
  
  loadProfile() {
    if (this.userID != null) {
      localStorage.setItem('idclient', this.userID);
      this.getProfile();
    }
  }

  getProfile() {
    this.getStorageIdClient();
    this.getStorageNomClient();
    this.getStoragePrenomClient();
  }
  /*
  * Variable stockées
  ******************************************/

  getStorageIdClient() {
    this.idclient = localStorage.getItem('idclient');//this.userID;
    this.test(this.idclient);
    /*this.storage.get('idclient').then((val) => {
      console.log("GetStorage : Id client : ", val);

      this.idclient = val;
      this.test(this.idclient);
    });*/
  }
  getStorageNomClient() {
    this.storage.get('nomclient').then((val) => {
      console.log('Nom client : ', val);
      this.nomclient = val;
    });
  }

  getStoragePrenomClient() {
    this.storage.get('prenomclient').then((val) => {
      console.log('Prenom client  : ', val);
      this.prenomclient = val;
    });
  }

  test(idclient: any) {
    console.log("TEST() : iclient : " + idclient);

    if (idclient == null) {
      console.log("Aucun client de connecté ");
      this.appPages = [
        {
          title: 'Login',
          url: '../../login',
          icon: 'person'
        }
      ]
    } else {
      console.log("Un client est connecté !");

      this.appPages = [
        {
          title: 'Home',
          url: 'tabs/home',
          icon: 'home'
        }, {
          title: 'Mon profil',
          url: 'profil',
          icon: 'person'
        },
        {
          title: 'Mes commandes',
          url: 'mescommandes',
          icon: 'mail'
        }, {
          title: ''
        }
      ]
    }//else

  }


  deconnexion() {

   // console.log("Bouton deconnexion");
    /*
    this.storage.get('idclient').then((val) => {
      console.log("ID : ", val);
      val = '';

      console.log("_____----_____---" , val)
    });*/
    localStorage.removeItem('idclient');
    // console.log("Déconexion, idclient actuel : "+   localStorage.getItem('idclient'));

    /*  this.storage.set('idclient', null);
      this.storage.get('idclient').then((val) => {
        console.log("Déconexion, idclient actuel : ", val);
      });*/
    this.storage.set('nomclient', '');
    this.storage.set('prenomclient', '');
    this.storage.set('adresseclient', '');
    this.storage.set('npaclient', '');
    this.storage.set('villeclient', '');
    this.storage.set('paysclient', '');
    this.storage.set('telephoneclient', '');
    this.storage.set('emailclient', '');
    this.storage.set('motdepasseclient', '');
    this.storage.set('is_activeclient', '');
   
   // location.reload();
    this.router.navigate(['/tabs/home']);

    //this.ionViewWillEnter();
    //this.getStorageIdClient();
  }


}
